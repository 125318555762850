<div class="container">

    <div class="content">

        <!-- section class="hero" #hero [style.height.px]="hero.offsetWidth / 2" fxLayout="column"
            fxLayoutAlign="flex-start center"-->

        <section class="hero" #hero fxLayout="column"
            fxLayoutAlign="flex-start center">

            <div class="sign-wrapper" fxLayout="column" fxLayoutAlign="center center">

                <img class="sign" src="/assets/img/sign.webp">

                <p>CERRADOS TEMPORALMENTE</p>
                <p>POR INVENTARIO</p>

            </div>


        </section>

        <section class="categories">

            <a class="category" *ngFor="let category of categories" [class]="'category-' + category.name"
                [routerLink]="category.link" [queryParams]="category.queryParams">

                <span class="category-background-text">{{ category['background-text'] }}</span>

                <div class="category-wrapper">
                    <div class="category-info">
                        <div class="category-starting-from">
                            <div class="category-starting-from-label">A PARTIR DE</div>
                            <div class="category-starting-from-currency">₲
                                {{ category['starting-from'] | currency:'PYG':'':'1.0-0'}}</div>
                        </div>
                        <div class="category-name">
                            {{ category.name }}
                            <div class="arrow">
                                <span class="line"></span>
                                <span class="point"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <img class="category-image" [src]="category.image" />

            </a>

        </section>

        <section class="featured">

            <h1 [style.padding.rem]="'2'">DESTACADOS</h1>

            <glide-items [items]="featuredGlideItems"></glide-items>

        </section>


        <section class="sale">

            <div fxLayoutAlign="space-between center" [style.padding.rem]="'1'">
                <h1>OFERTAS</h1>
                <a routerLink="/search" [queryParams]="{list: saleGlideLink}">
                    <h1>VER LISTA</h1>
                </a>
            </div>

            <glide-items [items]="saleGlideItems"></glide-items>

        </section>

    </div>

</div>