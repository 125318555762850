import { Component, Input, OnInit } from '@angular/core';

declare var FlipDown: any;

@Component({
  selector: 'flipdown',
  template: '<div id="flipdown" class="flipdown"></div>',
  styleUrls: ['./flipdown.component.css']
})
export class FlipdownComponent implements OnInit {

  //date e.g: '2020-11-28T15:00:00'
  @Input() date = new Date("2020-11-28T15:00:00")

  @Input() theme: string = "light";

  @Input() headings: Array<String> = ["DÍAS", "HORAS", "MINS", "SEGS"];

  ngOnInit(): void {

    var date = this.date instanceof Date ? this.date : new Date(this.date); 

    new FlipDown(date.getTime() / 1000, { theme: this.theme, headings: this.headings }).start();
  }

}
