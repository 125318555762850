import localePy from '@angular/common/locales/es-PY'
import { registerLocaleData } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { NgModule, LOCALE_ID } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { HeaderComponent } from './main/header/header.component';
import { FooterComponent } from './main/footer/footer.component';

import { NavMobileComponent } from './nav/mobile/nav-mobile.component';
import { NavbarComponent } from './nav/menu/navbar.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';

import { SearchResultsComponent } from './search/search-results/search-results.component';
import { SearchFiltersComponent } from './search/search-filters/search-filters.component';
import { SearchListComponent } from './search/search-list/search-list.component';
import { SearchItemComponent } from './search/search-item/search-item.component';
import { SearchSortComponent } from './search/search-sort/search-sort.component'
import { SearchPaginatorComponent } from './search/search-paginator/search-paginator.component';

import { TextMaskModule } from 'angular2-text-mask';
import { ItemComponent } from './item/item.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SaleTagComponent } from './components/label/sale-tag/sale-tag.component';
import { FlipdownComponent } from './components/flipdown/flipdown.component';
import { GlideComponent } from './components/glide/glide.component';
import { GlideItemsComponent } from './components/glide/glide-items/glide-items.component';

registerLocaleData(localePy);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavMobileComponent,
    FooterComponent,
    NavbarComponent,
    SearchResultsComponent,
    SearchFiltersComponent,
    SearchListComponent,
    SearchItemComponent,
    SearchSortComponent,
    SearchPaginatorComponent,
    ItemComponent,
    HomepageComponent,
    SaleTagComponent,
    FlipdownComponent,
    GlideComponent,
    GlideItemsComponent
  ],
  imports: [
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    MatTooltipModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    TextMaskModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-PY' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
